
import { defineComponent } from "vue";
import CreateCampaignForm from "@/views/notification/constituent/CreateCampaignForm.vue";

export default defineComponent({
  name: "CreateFiatCurrency",
  components: {
    CreateCampaignForm,
  },
  setup() {
    return {};
  },
});

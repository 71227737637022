<template>
  <div class="row justify-content-center my-5">
    <div class="card col-12 col-md-6">
      <DualRingLoader :loading="loading">
        <div class="card-body">
          <form class="" @submit.prevent="Submit">
            <div class="mb-3">
              <div>
                <label for="title" class="form-label"> Campaign Title </label>
                <InputWrapper :errors="formError.title">
                  <input
                    v-model="formData.title"
                    placeholder="Title"
                    class="form-control"
                    id="title"
                    required
                  />
                </InputWrapper>
              </div>
            </div>

            <div class="mb-3">
              <div>
                <label for="body" class="form-label"> Campaign Body </label>
                <InputWrapper :errors="formError.body">
                  <textarea
                    v-model="formData.body"
                    type="text"
                    placeholder="Campaign Body"
                    class="form-control"
                    id="body"
                    required
                  ></textarea>
                </InputWrapper>
              </div>
            </div>

            <div class="text-center">
              <button class="btn btn-dark">Create Campaign</button>
            </div>
          </form>
        </div>
      </DualRingLoader>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { apiPost } from "@/api";
import DualRingLoader from "@/components/loader/DualRingLoader.vue";
import { Toast } from "@/utils/notify";
import { ShowSuccess } from "@/utils/notify";
import InputWrapper from "@/components/InputWrapper.vue";

export default defineComponent({
  name: "CreateCampaignForm",
  components: { InputWrapper, DualRingLoader },
  emits: ["save"],
  setup() {
    const formData = ref({
      title: null,
      body: null,
    });
    const formError = ref({});
    const loading = ref(false);
    async function Submit() {
      loading.value = true;
      formError.value = {};
      try {
        await apiPost(`/notifications`, formData.value);
        ShowSuccess("Notifications Created Successfully", () => {
          formData.value = {
            title: null,
            body: null,
          };
        });
      } catch (e) {
        formError.value = e?.response?.data?.i ?? {};
        Toast.fire({
          icon: "warning",
          title: "Unable to create entry: " + e.message,
        }).then();
      }
      loading.value = false;
    }
    return {
      Submit,
      formData,
      formError,
      loading,
    };
  },
});
</script>

<style scoped></style>

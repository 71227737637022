<template>
  <section class="my-5">
    <div class="mb-5 text-center">
      <h1>Create Campaign</h1>
      <div>
        <small
          >Create push notification campaign to user's mobile devices</small
        >
      </div>
    </div>
    <CreateCampaignForm />
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CreateCampaignForm from "@/views/notification/constituent/CreateCampaignForm.vue";

export default defineComponent({
  name: "CreateFiatCurrency",
  components: {
    CreateCampaignForm,
  },
  setup() {
    return {};
  },
});
</script>

<style scoped></style>
